import { PropsWithChildren } from 'react'
import CircleCheckbox, { ICheckboxProps } from '@/shared/Inputs/CircleCheckbox/CircleCheckbox'

interface IProps extends ICheckboxProps {
  className?: string
}

const CheckboxWithInscription = (props: PropsWithChildren<IProps>) => {
  return (
    <div className="inline-flex" style={props.style}>
      <CircleCheckbox checked={props.checked} name={props.name} onChange={props.onChange} required={props.required} />
      <p className={props.className}>{props.children}</p>
    </div>
  )
}

export default CheckboxWithInscription
