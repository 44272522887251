import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import i18n from '@/translations/i18n'
import http from '@/shared/http'
import timeUtils from '@/utils/time.utils'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/store/store'
import { useDispatch } from 'react-redux'

const defaultLocale = 'ru'

const LanguageProvider = (props: PropsWithChildren) => {
  const router = useRouter()
  const [messages, setMessages] = useState(i18n.getMessages())

  const userLocale = useAppSelector((state) => state.auth.lang)
  const authState = useAppSelector((state) => state.auth.authState)

  const prevUserLocale = useRef(userLocale)
  const prevLocale = useRef(userLocale || defaultLocale)
  const dispatch = useDispatch()

  const reloadTranslation = useCallback(() => {
    router.reload()
  }, [])

  useEffect(() => {
    i18n.subscribe(setMessages)
  }, [dispatch])

  useEffect(() => {
    const normalizedLocale = userLocale || defaultLocale

    http.defaults.headers['Accept-Language'] = normalizedLocale === 'ru' ? 'ru-RU' : 'en-US'
    timeUtils.setLocale(normalizedLocale)

    if (prevLocale.current === userLocale) return
    if (userLocale && prevUserLocale.current && authState === 'logged_in') reloadTranslation()
    if (userLocale) prevUserLocale.current = userLocale
  }, [userLocale])

  return (
    <IntlProvider
      locale={userLocale}
      messages={messages[userLocale || defaultLocale] || {}}
      onError={noop}
      defaultLocale={defaultLocale}
    >
      <>{props.children}</>
    </IntlProvider>
  )
}

const noop = () => null

export default LanguageProvider
