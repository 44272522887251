import css from './loaderPage.module.css'
import CircleLogoLoader from '@/shared/Loaders/CircleLogoLoader/CircleLogoLoader'

interface IProps {
  inscription?: string
}

/** The page, that contains only loader */
const LoaderPage = (props: IProps) => {
  return (
    <div className={css.page}>
      <CircleLogoLoader />
      {props.inscription && (
        <span style={{ fontSize: '14px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>{props.inscription}</span>
      )}
    </div>
  )
}

export default LoaderPage
