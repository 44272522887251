import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { store } from '@/store/store'
import '@/styles/sanitize.css'
import '@/styles/variables.css'
import '@/styles/base.css'
import '@/styles/smoothDropdown.css'
import '@/styles/modal.styles.css'
import { RecoilRoot } from 'recoil'
import AuthProvider from '@/components/Auth/components/AuthProvider'
import ErrorBoundary from '@/shared/ErrorBoundary'
import Head from 'next/head'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { googleMeasurementId } from '@/shared/constants'
import useViewportHeight from '@/hooks/useViewportHeight'
import { useEffect } from 'react'
import LanguageProvider from '@/translations/components/LanguageProvider'
import '@/components/Purchase/translations/purchase.intl'
import '@/components/Shipping/translations/shipping.intl'
import '@/components/News/translations/news.intl'

const App = ({ Component, pageProps }: AppProps) => {
  const Page = Component as any
  useViewportHeight()

  useEffect(() => {
    if (navigator && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js ').catch(() => {})
    }
  }, [])

  return (
    <>
      <GoogleAnalytics gaMeasurementId={googleMeasurementId} trackPageViews={true} />
      <Head>
        <title>82BOX App</title>
      </Head>
      <Provider store={store}>
        <RecoilRoot>
          <ErrorBoundary>
            <LanguageProvider>
              <AuthProvider>
                <Page {...pageProps} />
              </AuthProvider>
            </LanguageProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </Provider>
    </>
  )
}

export default App
