import { configureStore } from '@reduxjs/toolkit'
import { Store } from 'redux'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { IStore } from '@/store/store.types'
import authReducer from '@/components/Auth/auth.reducer'
import userReducer from '@/components/User/user.reducer'

const reducer = {
  auth: authReducer,
  user: userReducer,
}

const getMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware<IStore>) => {
  return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
}

export const useAppSelector: TypedUseSelectorHook<IStore> = useSelector

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getMiddleware(getDefaultMiddleware),
}) as Store<IStore>
