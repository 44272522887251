import { IUser, UpdatableUserFields } from '@/components/User/user.types'
import axios from 'axios'
import { API_URL } from '@/shared/constants'
import http, { handleHttpError, handleHttpResponse } from '@/shared/http'
import { HTTPResponse } from '@/types/http.types'
import { IConfirmEmailResponse } from '@/components/Auth/auth.types'

/** Get the user info */
const getSelfInfo = async (accessToken: string): Promise<IUser> => {
  return axios
    .get(API_URL + '/api/v1/me/?expand=profile', {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((r) => r.data)
}

const patchSelf = async (updates: Partial<UpdatableUserFields>): Promise<HTTPResponse<UpdatableUserFields>> => {
  return http.patch('/api/v1/me/', updates).then(handleHttpResponse).catch(handleHttpError)
}

const patchSelfWithToken = async (
  updates: Partial<UpdatableUserFields>,
  access
): Promise<HTTPResponse<UpdatableUserFields>> => {
  return http
    .patch('/api/v1/me/', updates, { headers: { Authorization: `Bearer ${access}` } })
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const requestEmailUpdate = async (data: { email: string }): Promise<HTTPResponse<any>> => {
  return http.post('/api/v1/me/change_email/', { newEmail: data.email }).then(handleHttpResponse).catch(handleHttpError)
}

const confirmEmailUpdate = async (
  newEmail: string,
  confirmationCode: string
): Promise<HTTPResponse<IConfirmEmailResponse>> => {
  return http
    .post('/api/v1/me/change_email/confirm/', { newEmail, confirmationCode })
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const issueReferral = async () => {
  return http.post('/api/v1/me/referal_system/issue_code/').then(handleHttpResponse).catch(handleHttpError)
}

const userApi = { getSelfInfo, patchSelf, patchSelfWithToken, requestEmailUpdate, confirmEmailUpdate, issueReferral }
export default userApi
