import { useState } from 'react'
import useHttpLoader from '@/hooks/useHttpLoader'
import authApi from '@/components/Auth/auth.api'
import { batch, useDispatch } from 'react-redux'
import { authSliceActions } from '@/components/Auth/auth.reducer'
import authService from '@/components/Auth/auth.service'
import { userSliceActions } from '@/components/User/user.reducer'
import useValidationForm from '@/shared/Forms/ValidationForm/useValidationForm'
import useAuthConfirm from '@/components/Auth/components/AuthPage/AuthConfirm/useAuthConfirm'
import emailSuggestionsService from '@/components/Auth/services/emailSuggestions/emailSuggestions.service'
import { useAppSelector } from '@/store/store'
import userApi from '@/components/User/user.api'
import { useRouter } from 'next/router'
import { REFERRAL_ENABLED } from '@/shared/constants'

const useAuthPage = () => {
  const dispatch = useDispatch()

  const router = useRouter()
  const referral = router.query['ref']
  const { wait, loading } = useHttpLoader()

  const validationCtrl = useValidationForm()
  const [agreementApproved, setAgreementApproved] = useState(false)
  // phone number or email
  const [contact, setContact] = useState('')
  const [contactValid, setContactValid] = useState(false)
  const [contactSubmitted, setContactSubmitted] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [passwordRequired, setPasswordRequired] = useState(false)
  const [password, setPassword] = useState('')
  const [suggestionShown, setSuggestionShown] = useState(false)
  const selectedLanguage = useAppSelector((state) => state.auth.lang)

  // 0 - email, 1 - phone
  const [contactType, setContactType] = useState(0)

  const authConfirmCtrl = useAuthConfirm(contact, contactType, password)

  const handleAgreementToggle = () => setAgreementApproved((prev) => !prev)

  const handleConfirmationCodeChange = (code: string) => {
    if (code && isNaN(parseInt(code[code.length - 1]))) return

    setConfirmationCode(code)
    if (code.length === 6) {
      handleContactConfirm(code)
    }
  }

  const handleContactChange = (value: string, _, valid: boolean) => {
    setContact(value)
    setContactValid(valid)
  }

  const handleContactTypeChange = (contactIndex: number) => {
    if (contactIndex !== contactType) {
      setContact('')
      setContactType(contactIndex)
    }
  }

  const handleContactConfirmCancel = () => {
    validationCtrl.setErrorMsg('')
    validationCtrl.setErrors({})
    setContactSubmitted(false)
    setConfirmationCode('')
    authConfirmCtrl.setResendAvailable(true)
  }

  const handleSuggestDomainConfirm = (domain: string) => {
    setContact((prev) => {
      const domainName = prev.split('@')[0]
      return domainName + '@' + domain
    })

    validationCtrl.removeSuggestion('email')
  }

  const handleContactSubmit = () => {
    const payload: any = contactType === 0 ? { email: contact } : { phone: contact }
    if (passwordRequired) payload.password = password
    if (referral && REFERRAL_ENABLED) payload.referalCode = referral

    if (contactType === 0 && !suggestionShown) {
      const suggestingDomain = emailSuggestionsService.getSuggestion(contact)

      if (suggestingDomain) {
        setSuggestionShown(true)
        validationCtrl.createSuggestion(
          'email',
          <span className="text-14px">
            Возможно, вы имели в виду{' '}
            <span
              className="text-bold text-14px span-link"
              onClick={() => handleSuggestDomainConfirm(suggestingDomain)}
            >
              {suggestingDomain}
            </span>
            {'\n'}Если нет, еще раз нажите "Войти".
          </span>
        )
        return
      } else {
        validationCtrl.removeSuggestion('email')
      }
    } else {
      validationCtrl.removeSuggestion('email')
    }

    wait(authApi.submitContact(payload), (resp) => {
      if (authConfirmCtrl.helpPanelOpen) authConfirmCtrl.toggleHelpPanel()

      const needPassword = resp.status === 'error' && resp.code === 'missing_password'
      setPasswordRequired(needPassword)
      if (needPassword) return

      if (resp.status === 'error') {
        return validationCtrl.handleErrorResponse(resp)
      }

      validationCtrl.setErrorMsg('')
      validationCtrl.setErrors({})

      setContactSubmitted(true)
    })
  }

  const handleContactConfirm = (code?: string) => {
    const payload = contactType === 0 ? { email: contact } : { phone: contact }

    wait(authApi.confirmContactWithInitDataInit({ ...payload, password }, code || confirmationCode), (resp) => {
      authConfirmCtrl.setResendAvailable(true)

      if (resp.status === 'error') {
        return validationCtrl.handleErrorResponse(resp)
      }

      const { refresh, accessExpires, access } = resp.body.tokens
      authService.cacheRefreshToken(refresh)

      if (selectedLanguage === 'en') {
        userApi.patchSelfWithToken({ lang: 'EN' }, access)
      }

      batch(() => {
        dispatch(
          authSliceActions.update({
            authState: 'logged_in',
            accessToken: access,
            accessExpires,
            refreshToken: refresh,
            errorMessage: null,
            lang: selectedLanguage === 'en' ? 'en' : resp.body.tokens.lang.toLowerCase(),
          })
        )
        dispatch(userSliceActions.update({ user: resp.body.user }))
      })
    })
  }

  return {
    contact,
    contactValid,
    handleEmailChange: handleContactChange,
    confirmationCode,
    setConfirmationCode: handleConfirmationCodeChange,
    handleContactSubmit,
    handleContactConfirm,
    handleContactConfirmCancel,
    contactSubmitted,
    loading,
    error: validationCtrl.errorMsg,
    errors: validationCtrl.errors,
    setIsValid: validationCtrl.setIsValid,
    agreementApproved,
    handleAgreementToggle,
    contactType,
    handleContactTypeChange,
    authConfirmCtrl,
    passwordRequired,
    password,
    setPassword,
    suggestions: validationCtrl.suggestions,
  }
}

export default useAuthPage
