import { useAppSelector } from '@/store/store'
import { useDispatch } from 'react-redux'
import { authSliceActions } from '@/components/Auth/auth.reducer'
import { Lang } from '@/components/User/user.types'
import cn from '@/shared/cn'
import userApi from '@/components/User/user.api'

interface IProps {
  onChange?: (lang: Lang) => void
  className?: string
}

const AuthLanguagePicker = (props: IProps) => {
  const dispatch = useDispatch()
  const userLocale = useAppSelector((state) => state.auth.lang)
  const authState = useAppSelector((state) => state.auth.authState)

  const handleSwitch = () => {
    if (userLocale === 'en') {
      if (props.onChange) props.onChange('ru')
      if (authState === 'logged_in') userApi.patchSelf({ lang: 'RU' })
      dispatch(authSliceActions.update({ lang: 'ru' }))
    } else {
      if (props.onChange) props.onChange('en')
      if (authState === 'logged_in') userApi.patchSelf({ lang: 'EN' })
      dispatch(authSliceActions.update({ lang: 'en' }))
    }
  }

  const getText = () => {
    if (userLocale === 'en') return 'Перейти на русский'

    return 'Switch to english'
  }

  return (
    <p
      className={cn('text-14px text--primary span-link text-bold', props.className || '')}
      style={{ textTransform: 'uppercase', textAlign: 'center', gap: '32px' }}
      onClick={handleSwitch}
    >
      {getText()}
    </p>
  )
}

export default AuthLanguagePicker
