import css from '@/shared/Layouts/FormLayout/formLayout.module.css'
import Logo from '@/public/mainLogo.svg'
import { useRouter } from 'next/router'
import { CSSProperties, PropsWithChildren } from 'react'
import Footer from '@/shared/Layouts/components/Footer'

const LogoLayout = (props: PropsWithChildren<{ withContentPadding?: boolean; headerStyle?: CSSProperties }>) => {
  const router = useRouter()

  return (
    <div className={css.wrapper} data-withContentPadding={props.withContentPadding} style={{ paddingBottom: '70px' }}>
      <div
        className={`${css.header} flex-space-between`}
        data-withContentPadding={props.withContentPadding}
        style={props.headerStyle}
      >
        <Logo
          onClick={() => router.push('/')}
          style={{ transform: 'scale(0.5) translateX(-50%)', marginRight: 'auto', marginLeft: '20px' }}
        />
      </div>
      {props.children}
      <Footer />
    </div>
  )
}

export default LogoLayout
