import fmt from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'
import { Lang } from '@/components/User/user.types'
import { Locale } from 'date-fns'

let locale: Locale = ru

const setLocale = (lang: Lang) => {
  switch (lang) {
    case 'en':
      locale = en
      break
    case 'ru':
      locale = ru
  }
}

export const formatDate = (date: Date | number, str: string) => {
  return fmt(date, str, { locale })
}

const getCurrentDate = (): Date => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date)
  dateCopy.setDate(dateCopy.getDate() + days)
  return dateCopy
}

const timeUtils = { formatDate, getCurrentDate, addDays, setLocale }

export default timeUtils
