import emailDomainsList, { domainNamesToDomains } from '@/components/Auth/services/emailSuggestions/emailDomains.list'

const levenshtein = (a: string, b: string): number => {
  const an = a ? a.length : 0
  const bn = b ? b.length : 0

  if (an === 0) return bn
  if (bn === 0) return an

  const matrix = new Array<number[]>(bn + 1)

  for (let i = 0; i <= bn; ++i) {
    let row = (matrix[i] = new Array<number>(an + 1))
    row[0] = i
  }

  const firstRow = matrix[0]
  for (let j = 1; j <= an; ++j) firstRow[j] = j

  for (let i = 1; i <= bn; ++i) {
    for (let j = 1; j <= an; ++j) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1], matrix[i][j - 1], matrix[i - 1][j]) + 1
      }
    }
  }
  return matrix[bn][an]
}

const extractDomain = (email: string) => {
  return email.split('@')[1]
}

const extractDomainName = (domain: string) => {
  const lastDot = domain.lastIndexOf('.')
  if (lastDot === -1) return null

  return domain.slice(0, lastDot)
}

const extractTld = (domain: string) => {
  const lastDot = domain.lastIndexOf('.')
  if (lastDot === -1) return null

  return domain.slice(lastDot + 1)
}

const getSuggestion = (email: string): string | null => {
  let suggestingDomain = null
  let lowestDistance = Number.MAX_SAFE_INTEGER

  const domain = extractDomain(email)
  if (!domain) return suggestingDomain

  const domainName = extractDomainName(domain)

  if (domainName && domainNamesToDomains[domainName]) {
    const tld = extractTld(domain)
    if (tld === domainNamesToDomains[domainName]) {
      return null
    } else {
      return domainName + '.' + domainNamesToDomains[domainName]
    }
  }

  for (const d of emailDomainsList) {
    const distance = levenshtein(d, domain)

    if (distance < lowestDistance) {
      lowestDistance = distance
      suggestingDomain = d
    }
  }

  if (lowestDistance <= 3 && lowestDistance !== 0) return suggestingDomain
  return null
}

const emailSuggestionsService = { getSuggestion }
export default emailSuggestionsService
