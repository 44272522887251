import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReferralCode, IUser, IUserState } from '@/components/User/user.types'
import { IRecipient } from '@/components/Shipping/Recipients/recipient.types'

const initialState: IUserState = {
  user: null,
  recipients: [],
}

const slice = createSlice({
  name: 'etba/user',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<IUserState>>) => {
      for (const key in action.payload) state[key] = action.payload[key]
    },
    userUpdated: (state, action: PayloadAction<Partial<IUser>>) => {
      for (const key in action.payload) {
        state.user[key] = action.payload[key]
      }
    },
    referralIssued: (state, action: PayloadAction<IReferralCode>) => {
      state.user.profile.referalCodes = [action.payload]
    },
    addRecipient: (state, action: PayloadAction<IRecipient[]>) => {
      for (const r of action.payload) {
        if (!state.recipients.some((res) => r.id === res.id)) {
          state.recipients.push(r)
        }
      }
    },
    recipientUpdated: (state, action: PayloadAction<IRecipient>) => {
      const index = state.recipients.findIndex((r) => r.id === action.payload.id)
      if (index === -1) return

      state.recipients[index] = { ...state.recipients[index], ...action.payload }
    },
    recipientRemoved: (state, action: PayloadAction<IRecipient['id']>) => {
      state.recipients = state.recipients.filter((r) => r.id !== action.payload)
    },
  },
})

export const userSliceActions = slice.actions

const userReducer = slice.reducer
export default userReducer
