import { CSSProperties, PropsWithChildren, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

interface IProps {
  className?: string
  style?: CSSProperties
  maxHeight?: number
  overflow?: 'visible' | 'hidden' | 'auto'
  fillMode?: FillMode
  open: boolean
}

const SmoothDropdown = ({
  children,
  className,
  style,
  maxHeight,
  overflow = 'auto',
  open,
}: PropsWithChildren<IProps>) => {
  const nodeRef = useRef<HTMLDivElement>(null)

  const handleEnter = () => {
    if (!nodeRef.current) return

    const ht = maxHeight ? Math.min(maxHeight, nodeRef.current.offsetHeight) : nodeRef.current.offsetHeight

    if (maxHeight && nodeRef.current.offsetHeight > maxHeight) nodeRef.current.style.overflow = overflow
    else nodeRef.current.style.overflow = 'hidden'

    nodeRef.current.style.maxHeight = '0'
    nodeRef.current.dataset.targetHeight = ht + 'px'
  }

  const handleEntering = () => {
    if (!nodeRef.current) return

    nodeRef.current.style.maxHeight = nodeRef.current.dataset.targetHeight
    delete nodeRef.current.dataset.targetHeight
  }

  const handleEntered = () => {
    nodeRef.current.style.overflow = overflow
    if (!maxHeight) nodeRef.current.style.maxHeight = '10000px'
  }

  const handleExit = () => {
    if (!nodeRef.current) return

    nodeRef.current.style.maxHeight = nodeRef.current.offsetHeight + 'px'
    if (!maxHeight || nodeRef.current.offsetHeight < maxHeight) nodeRef.current.style.overflow = 'hidden'
    else nodeRef.current.style.overflow = 'auto'
  }

  return (
    <CSSTransition
      in={open}
      timeout={300}
      unmountOnExit={true}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      classNames="smoothDropdownBehavior"
      onExit={handleExit as any}
      nodeRef={nodeRef}
    >
      <div className={className} style={style} ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default SmoothDropdown
