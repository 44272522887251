const refreshKey = 'etba/auth/refreshToken'

const getCachedRefreshToken = (): string | undefined => {
  return localStorage.getItem(refreshKey)
}

const cacheRefreshToken = (token: string) => {
  localStorage.setItem(refreshKey, token)
}

const authService = {
  getCachedRefreshToken,
  cacheRefreshToken,
}

export default authService
