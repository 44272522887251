import FormLayout from '@/shared/Layouts/FormLayout/FormLayout'
import Button from '@/shared/Button/Button'
import SplatNumberInput from '@/shared/Inputs/SplatNumberInput/SplatNumberInput'
import SmoothDropdown from '@/shared/Animated/SmoothDropdownBehaivor/SmoothDropdown'
import ValidationForm from '@/shared/Forms/ValidationForm/ValidationForm'
import { allowPhoneAuth } from '@/shared/constants'
import { useIntl } from 'react-intl'

interface IProps {
  loading: boolean
  contact: string
  code: string
  onCodeChange: (value: string, name: string) => void
  onCancel: () => void
  onConfirm: () => void
  error?: string

  toggleHelpPanel: () => void
  helpPanelOpen: boolean
  resendCode: () => void
  isResendBlocked: boolean
  resendAvailable: boolean
  secondsRemaining: number
  handleSupportHelp: () => void

  contactType: number
}

const AuthConfirm = (props: IProps) => {
  const intl = useIntl()

  const renderPanel = () => {
    return (
      <>
        <span onClick={props.toggleHelpPanel} className="span-link text-14px text--grey">
          {intl.formatMessage({ id: 'auth.not_received' })}
        </span>
        <SmoothDropdown open={props.helpPanelOpen}>
          <p className="form-subheader">{intl.formatMessage({ id: 'auth.nothing_bag' })} 🙂</p>
          <p className="form-subheader">
            {props.contactType === 0
              ? intl.formatMessage({ id: 'auth.recheck_email' })
              : intl.formatMessage({ id: 'auth.recheck_sms' })}
          </p>
          {props.contactType === 0 && <p className="form-subheader">{intl.formatMessage({ id: 'auth.send_again' })}</p>}
          {allowPhoneAuth && (
            <p className="form-subheader">
              {props.contactType === 0
                ? 'Если и это не помогло, то попробуйте запросить код с помощью номера телефона.'
                : 'Если код так и не пришёл, запросите его другим способом, нажав «войти по email».'}
            </p>
          )}
          <Button theme="primary" onClick={props.resendCode} disabled={props.isResendBlocked} type="button">
            {props.isResendBlocked
              ? intl.formatMessage({ id: 'auth.resend_remain' }, { secondsRemaining: props.secondsRemaining })
              : intl.formatMessage({ id: 'auth.send_again_action' })}
          </Button>
          <p className="form-subheader form-subheader-top-padding">
            {intl.formatMessage({ id: 'auth.ask_support' })} 🤗
          </p>
          <Button theme="grey" onClick={props.handleSupportHelp} type="button">
            {intl.formatMessage({ id: 'auth.ask_support_action' })}
          </Button>
        </SmoothDropdown>
        {!props.resendAvailable && (
          <p className="text-18px text--critical" style={{ marginTop: '1em' }}>
            {intl.formatMessage({ id: 'auth.code_has_been_resent' })} 💌
          </p>
        )}
      </>
    )
  }

  return (
    <FormLayout onBackClick={props.onCancel}>
      <ValidationForm className="flex-form flex-grow-1 text--default" onSubmit={props.onConfirm}>
        <h2 className="primary-header">
          {props.contactType === 0
            ? intl.formatMessage({ id: 'auth.code_from_email' })
            : intl.formatMessage({ id: 'auth.code_from_sms' })}
        </h2>
        <p className="form-subheader">
          {intl.formatMessage({ id: 'auth.check_email' })} <span className="text-700">{props.contact}</span>{' '}
          {intl.formatMessage({ id: 'auth.enter_code' })}
        </p>
        <SplatNumberInput
          value={props.code}
          name={'confirm_code'}
          onChange={props.onCodeChange}
          digitsCount={6}
          invalid={Boolean(props.error)}
        />
        {props.error && (
          <p className="text--danger" style={{ whiteSpace: 'pre-wrap' }}>
            {props.error}
          </p>
        )}

        {renderPanel()}
        <div className="form-footer">
          <Button type="submit" theme="primary" disabled={props.code.length !== 6} loading={props.loading}>
            {intl.formatMessage({ id: 'auth.next' })}
          </Button>
        </div>
      </ValidationForm>
    </FormLayout>
  )
}

export default AuthConfirm
