import { ReactNode } from 'react'
import css from './tabs.module.css'

interface IProps {
  tabsContent: (ReactNode | JSX.Element)[]
  selectedTab: number
  onTabSelect: (tabIndex: number) => void
}

const Tabs = (props: IProps) => {
  const renderTabs = () => {
    return props.tabsContent.map((tabContent, index) => (
      <div
        key={index}
        className={css.tab}
        data-active={index === props.selectedTab}
        onClick={() => props.onTabSelect(index)}
      >
        {tabContent}
      </div>
    ))
  }

  return <div className={css.wrapper}>{renderTabs()}</div>
}

export default Tabs
