import { CSSProperties, useState } from 'react'
import noimage from '@/public/noimage.svg'
import css from './image.module.css'
import NextImage, { StaticImageData } from 'next/image'
import loadergif from '@/public/loader.gif'
import loadergiftransparent from '@/public/loader-transparent-bg.gif'

interface BaseProps {
  quality?: number
  priority?: boolean
  onClick?: () => void
  src: string | StaticImageData
  className?: string
  style?: CSSProperties
  alt: string
  uploading?: boolean

  wrapperStyle?: CSSProperties
  wrapperClassname?: string
  unoptimized?: boolean
  displayLoader?: boolean
}

interface PropsWithDemensions extends BaseProps {
  width: number | string
  height: number | string
}

interface PropsWithFill extends BaseProps {
  fill: true
}

type Props = PropsWithDemensions | PropsWithFill

const Image = (props: Props) => {
  const [loading, setLoading] = useState(Boolean(props.src))
  const [error, setError] = useState(!Boolean(props.src))

  const handleError = () => {
    setError(true)
    setLoading(false)
  }

  const normalizedStyle = props.style || {}
  if (error) normalizedStyle.padding = '20%'
  return (
    <div className={`${css.wrapper} ${props.wrapperClassname || ''}`} style={props.wrapperStyle}>
      {loading && props.displayLoader !== false && (
        <div className={css.loader} style={{ background: `url(${loadergif.src})` }} />
      )}
      {props.uploading && <div style={{ background: `url(${loadergiftransparent.src})` }} className={css.uploader} />}
      <NextImage
        alt={error ? 'Изображение не найдено' : props.alt}
        style={{ objectPosition: 'center', objectFit: 'contain', zIndex: 2, ...normalizedStyle }}
        src={error ? noimage : props.src}
        className={props.className || css.image}
        key={error ? 1 : 0}
        onLoad={() => setLoading(false)}
        onLoadingComplete={() => setLoading(false)}
        onError={handleError}
        width={'width' in props ? (props.width as any) : undefined}
        height={'height' in props ? (props.height as any) : undefined}
        quality={props.quality}
        priority={props.priority}
        onClick={props.onClick}
        fill={'fill' in props ? true : undefined}
        sizes={'fill' in props ? '100vw' : undefined}
        unoptimized={props.unoptimized}
        loader={
          props.unoptimized
            ? ({ src }) => {
                return `${src}`
              }
            : undefined
        }
      />
    </div>
  )
}

export default Image
