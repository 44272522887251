export const API_URL = process.env.NEXT_PUBLIC_API_URL || 'https://dev-store.82box.ru'
export const SUPPORT_API_URL = process.env.NEXT_PUBLIC_SUPPORT_API_URL || 'https://dev-bot.82box.ru'
export const REFERRAL_ENABLED = process.env.NEXT_PUBLIC_REFERRAL_ENABLED
  ? process.env.NEXT_PUBLIC_REFERRAL_ENABLED === 'true'
  : true
export const SMTH_WENT_WRONG_ERR = 'Что-то пошло не так.. Попробуйте еще раз'
export const RATELIMIT_ERR = 'Слишком много запросов... Попробуйте повторить попытку позднее'
export const SUPPORT_MAIL = 'support@82box.ru'
export const allowPhoneAuth = process.env.NEXT_PUBLIC_SUPPORT_PHONE_AUTH === 'true'
export const googleMeasurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || 'G-JWQWNMNKTL'

export const integrationSignUpConfig = {
  campaignId: '',
  waitlistId: '',
  code: '',
}
