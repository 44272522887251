const detectStringLang = (s: string) => {
  const enRegexp = /^[A-Za-z0-9]*$/

  for (const c of s) {
    if (isLetter(c)) {
      if (!enRegexp.test(c)) return 'ru'
    }
  }

  return 'en'
}

function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase()
}

const textInputUtils = { detectStringLang }
export default textInputUtils
