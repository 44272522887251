import css from './circleCheckbox.module.css'
import { CSSProperties, useContext, useEffect, useRef } from 'react'
import errorContext from '@/shared/Forms/ValidationForm/ErrorContext'

export interface ICheckboxProps {
  required?: boolean
  checked: boolean
  name: string
  onChange: (value: boolean, name: string) => void
  style?: CSSProperties
}

const CircleCheckbox = (props: ICheckboxProps) => {
  const context = useContext(errorContext)
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    let validationMessage = ''
    if (props.required && !ref.current.checked) validationMessage = '-'

    context.onValidate(props.name, validationMessage)
  }, [])

  const handleChange = () => {
    let validationMessage = ''
    if (props.required && props.checked) validationMessage = '-'

    context.onValidate(props.name, validationMessage)

    props.onChange(!props.checked, props.name)
  }

  return (
    <div className={css.checkbox} onClick={handleChange} style={props.style}>
      <input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={() => {}}
        required={props.required}
        ref={ref}
      />
      <label htmlFor="checkbox" />
    </div>
  )
}

export default CircleCheckbox
