import { PropsWithChildren, useEffect, useRef } from 'react'
import { refreshToken } from '@/shared/http'
import userApi from '@/components/User/user.api'
import { userSliceActions } from '@/components/User/user.reducer'
import { batch, useDispatch } from 'react-redux'
import { useAppSelector } from '@/store/store'
import AuthPage from '@/components/Auth/components/AuthPage'
import LoaderPage from '@/shared/Loaders/LoaderPage/LoaderPage'
import '../translations/auth.intl'
import { authSliceActions } from '@/components/Auth/auth.reducer'
import { useIntl } from 'react-intl'

const AuthProvider = (props: PropsWithChildren) => {
  const intl = useIntl()
  const loadedUser = useRef(false)
  const dispatch = useDispatch()
  const authState = useAppSelector((state) => state.auth.authState)
  const user = useAppSelector((state) => state.user?.user)

  useEffect(() => {
    if (loadedUser.current === true) return
    loadedUser.current = true

    refreshToken().then((token) => {
      if (token) {
        userApi.getSelfInfo(token).then((r) => {
          batch(() => {
            dispatch(authSliceActions.update({ lang: r.lang.toLowerCase() }))
            dispatch(userSliceActions.update({ user: r }))
          })
        })
      } else {
        localStorage.clear()
      }
    })
  }, [])

  if (authState === 'logged_out') return <AuthPage />
  if (authState === 'unknown' || !user?.id) {
    return <LoaderPage inscription={intl.formatMessage({ id: 'trying_auth' })} />
  }

  return <>{props.children}</>
}

export default AuthProvider
