import { PropsWithChildren } from 'react'
import css from './cuteLayout.module.css'
import Logo from '@/public/mainLogo.svg'
import flowerSrc from '@/public/flower.png'
import circleLogoSrc from '@/public/circleLogo.png'
import tubeSrc from '@/public/tube.png'
import bgSrc from '@/public/gradientBackground.jpg'
import Image from '@/shared/Image'

/** Layout with stars, flowers and colorful logos in the background  */
const CuteLayout = (props: PropsWithChildren) => {
  return (
    <div className={css.bg} style={{ backgroundImage: `url("${bgSrc.src}")` }}>
      <div className={css.wrapper}>
        <div className={`${css.imageWrapper} ${css.flowerImage}`}>
          <Image src={flowerSrc} alt="flower" width={100} height={90} displayLoader={false} />
        </div>
        <div className={`${css.imageWrapper} ${css.circleLogoImage}`}>
          <Image src={circleLogoSrc} alt="logo" width={95} height={95} displayLoader={false} />
        </div>
        <div className={`${css.imageWrapper} ${css.tubeImage}`}>
          <Image src={tubeSrc} alt="tube" width={60} height={80} displayLoader={false} />
        </div>
        <div className={css.header}>
          <Logo />
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default CuteLayout
