import { ChangeEvent, CSSProperties, useContext, useEffect, useRef, WheelEventHandler } from 'react'
import errorContext from '@/shared/Forms/ValidationForm/ErrorContext'
import textInputUtils from '@/shared/Inputs/TextInput/textInput.utils'
import useVirtualMenu from '@/hooks/useVirtualMenu'
import usePopup from '@/hooks/usePopup'
import { useIntl } from 'react-intl'

export interface ITextInputProps {
  value: string
  name: string
  onChange: (val: string, name: string, valid: boolean) => void
  disabled?: boolean
  required?: boolean // default true
  autofocus?: boolean
  type?: string
  style?: CSSProperties
  wrapperStyle?: CSSProperties
  className?: string
  placeholder?: string
  maxLength?: number
  minLength?: number
  listenForAutofill?: boolean
  autoComplete?: string
  validator?: (s: string) => string | null
  lang?: 'en' | 'ru'
  pattern?: string
  onBlur?: () => void
}

const TextInput = ({ onChange, value, ...props }: ITextInputProps) => {
  const intl = useIntl()
  const ref = useRef<HTMLInputElement>()
  const suggestionRef = useRef<HTMLDivElement>(null)
  const { open: suggestionOpen, setOpen: setSuggestionOpen } = usePopup(suggestionRef)

  const context = useContext(errorContext)
  const error = context.getError(props.name)

  const suggestion = context.getSuggestion(props.name)
  const suggestionPosition = useVirtualMenu({ elementRef: ref, isOpen: false })

  useEffect(() => {
    if (!suggestion) {
      setSuggestionOpen(false)
      return
    }

    setSuggestionOpen(true)
    const timeout = setTimeout(() => setSuggestionOpen(false), 10000)
    return () => {
      clearTimeout(timeout)
    }
  }, [suggestion])

  useEffect(() => {
    if (!props.required) return context.onValidate(props.name, '')

    if (error && error !== '-') return
    let validationMessage = ref.current.validity.valid ? '' : '-'
    if (props.validator && !validationMessage) validationMessage = props.validator(ref.current.value)
    if (value && props.lang && textInputUtils.detectStringLang(ref.current.value) !== props.lang) {
      if (props.lang === 'en') {
        validationMessage = intl.formatMessage({ id: 'must_be_in_en' })
      } else {
        validationMessage = intl.formatMessage({ id: 'must_be_in_ru' })
      }
    }

    context.onValidate(props.name, validationMessage)

    return () => {
      context.onValidate(props.name, '')
    }
  }, [props.required, props.lang])

  const handleBlur = () => {
    if (!ref.current) return

    onChange(ref.current.value, props.name, ref.current.validity.valid)
    let validationMessage = ref.current.validity.valid ? '' : '-'
    if (props.validator && !validationMessage) validationMessage = props.validator(ref.current.value)
    if (value && props.lang && textInputUtils.detectStringLang(ref.current.value) !== props.lang) {
      if (props.lang === 'en') {
        validationMessage = intl.formatMessage({ id: 'must_be_in_en' })
      } else {
        validationMessage = intl.formatMessage({ id: 'must_be_in_ru' })
      }
    }

    if (props.onBlur) props.onBlur()
    context.onValidate(props.name, validationMessage)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let validationMessage = e.target.validity.valid ? '' : '-'
    if (props.validator && !validationMessage) validationMessage = props.validator(ref.current.value)
    if (props.lang && textInputUtils.detectStringLang(e.target.value) !== props.lang) validationMessage = '-'

    context.onValidate(props.name, validationMessage)
    onChange(e.target.value.trimStart(), props.name, e.target.validity.valid)
  }

  const preventWheel: WheelEventHandler<HTMLInputElement> = () => {
    if (props.type !== 'number') return

    ref.current.blur()
    return false
  }

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <input
        className="etba-input"
        {...props}
        value={value ?? ''}
        required={props.required ?? true}
        onChange={handleChange}
        ref={ref}
        data-invalid={Boolean(error) && !props.disabled}
        onBlur={handleBlur}
        onWheel={preventWheel}
      />
      {error && error !== '-' && <p className="etba-input_error text--danger">{error}</p>}
      {suggestionOpen && suggestion && (
        <div data-position={suggestionPosition} className="etba-input_suggestion" ref={suggestionRef}>
          {suggestion}
        </div>
      )}
    </div>
  )
}

export default TextInput
