import * as Sentry from '@sentry/nextjs'
import Button from '@/shared/Button/Button'
import { handleSupportHelp } from '@/components/Auth/components/AuthPage/AuthConfirm/useAuthConfirm'
import { WithRouterProps } from 'next/dist/client/with-router'
import { Component, PropsWithChildren } from 'react'
import { withRouter } from 'next/router'
import ErrorIcon from '@/public/500-icon.svg'
import LogoLayout from '@/shared/Layouts/LogoLayout/LogoLayout'

class ErrorBoundary extends Component<PropsWithChildren<WithRouterProps>, State> {
  state: State = { hasError: false, errorMsg: '' }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMsg: error.message }
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error)
    console.error(error, info)
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <LogoLayout>
        <div className="flex-form flex-grow-1 flex-horizontal-centered" style={{ textAlign: 'center' }}>
          <ErrorIcon />
          <h2 className="primary-header">К сожалению, произошла непредвиденная ошибка</h2>
          <p className="text-14px">Мы уже получили уведомление и работаем над ее устранением.</p>
        </div>
        <div className="form-footer">
          <Button theme="grey" onClick={() => handleSupportHelp(`Ошибка 'Страница не найдена'`)}>
            Написать в техподдержку
          </Button>
          <Button theme="primary" onClick={() => this.props.router.push('/')}>
            Вернуться на главную
          </Button>
        </div>
      </LogoLayout>
    )
  }
}

interface State {
  hasError: boolean
  errorMsg: string
}

export default withRouter(ErrorBoundary)
