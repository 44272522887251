import css from '@/shared/Inputs/SplatNumberInput/splatNumberInput.module.css'
import { ChangeEvent, useEffect, useRef, ClipboardEvent } from 'react'

interface IProps {
  value: string

  index: number
  onChange: (value: string, index: number) => void

  active: boolean
  onNext: (value: string, index: number) => void
  onPrev: (index: number) => void
  onPaste: (value: string) => void
  invalid?: boolean
}

const SplatInput = (props: IProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!ref.current) return
    if (props.active) ref.current.focus()
  }, [props.active])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    // If the user tried to enter a letter
    if (value && isNaN(parseInt(value))) return
    if (value.length == 2 && isNaN(parseInt(value[1]))) return

    if (!value) props.onPrev(props.index)

    if (value.length === 2) props.onNext(value[1], props.index)
    else props.onChange(value, props.index)
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const value = e.clipboardData.getData('Text')
    if (!value) return

    props.onPaste(value)
  }

  return (
    <input
      className={css.input}
      value={props.value}
      ref={ref}
      data-error={props.invalid}
      data-invalid={!Boolean(props.value)}
      onChange={handleChange}
      onPaste={handlePaste}
      inputMode="numeric"
    />
  )
}

export default SplatInput
