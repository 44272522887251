import axios, { AxiosError, AxiosResponse } from 'axios'
import { API_URL, RATELIMIT_ERR, SMTH_WENT_WRONG_ERR } from '@/shared/constants'
import { store } from '@/store/store'
import { IAuthState, IRefreshRequestResponse } from '@/components/Auth/auth.types'
import { authSliceActions } from '@/components/Auth/auth.reducer'
import authService from '@/components/Auth/auth.service'
import { IHTTPErrorResponse, IHTTPSuccessResponse } from '@/types/http.types'
import * as Sentry from '@sentry/nextjs'

const http = axios.create({
  baseURL: API_URL,
  adapter: require('axios/lib/adapters/xhr'),
})

const state: IAuthState = {
  authState: 'unknown',
  accessToken: null,
  refreshToken: null,
  errorMessage: null,
  accessExpires: 0,
  lang: null,
}
let refreshRequest = Promise.resolve('')
let isRefreshing = false

/**
 * Update the header with authorization if the token is updated
 */
store.subscribe(() => {
  const auth = store.getState().auth

  for (const key in auth) state[key] = auth[key]
  http.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`
})

/**
 * Before each request, check if the access token is still valid,
 * and if not, try to update it
 */
http.interceptors.request.use(async (config) => {
  let access = await refreshToken()
  if (!access) access = state.accessToken

  config.headers.Authorization = `Bearer ${access}`
  return config
})

/**
 * Check the server response and in case of errors related to authorization
 * redirect the user to the login page
 */
http.interceptors.response.use(
  (response) => response,
  (err) => {
    const shouldLogout = err.response && err.response.status === 401
    if (shouldLogout) {
      store.dispatch(authSliceActions.logout())
    }

    throw err
  }
)

/**
 * Get new access token
 */
export const refreshToken = async (): Promise<string> => {
  if (isRefreshing) return refreshRequest
  if (state.accessToken && state.accessExpires - Date.now() - 3_600_000 > 0) return state.accessToken

  isRefreshing = true
  const refreshToken = state?.refreshToken || authService.getCachedRefreshToken()

  if (!refreshToken) {
    store.dispatch(authSliceActions.logout())
  } else {
    refreshRequest = axios
      .post(API_URL + '/api/auth/refresh/', { refresh: refreshToken })
      .then((response: AxiosResponse<IRefreshRequestResponse>) => {
        isRefreshing = false
        const { access, accessExpires } = response.data
        const action = authSliceActions.update({
          authState: 'logged_in',
          accessToken: access,
          accessExpires: accessExpires * 1000,
        })

        store.dispatch(action)
        return access
      })
      .catch((err) => {
        isRefreshing = false
        if (!err.response || (err.response.status !== 401 && err.response.status !== 403)) {
          const action = authSliceActions.update({
            authState: 'server_error',
            errorMessage: SMTH_WENT_WRONG_ERR,
          })

          store.dispatch(action)
          return ''
        } else {
          store.dispatch(authSliceActions.logout())
          return ''
        }
      })
  }

  return refreshRequest
}

export const handleHttpResponse = <T = any>(response: AxiosResponse<T>): IHTTPSuccessResponse<T> => {
  return { status: 'success', body: response.data }
}

export const handleHttpError = (error: AxiosError): IHTTPErrorResponse => {
  if (error?.response?.status === 429) return { status: 'error', message: RATELIMIT_ERR }

  const code = error?.response?.data?.code

  Sentry.captureException(error)
  if (error?.response?.status === 400) {
    const validationError: Record<string, string[] | string> = error?.response?.data
    if (validationError) {
      for (const key in validationError) {
        if (Array.isArray(validationError[key])) validationError[key] = validationError[key][0]
      }
    }

    return {
      status: 'error',
      message:
        'С вашими данными что-то не так 🤔\n' +
        'Проверьте поля, отмеченные красным, при необходимости исправьте и попробуйте еще раз.',
      body: error?.response?.data,
      code,
    }
  }

  return { status: 'error', message: error?.message ?? SMTH_WENT_WRONG_ERR, code }
}

export default http
