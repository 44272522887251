import { ITextInputProps } from '@/shared/Inputs/TextInput/TextInput'
import css from './phoneInput.module.css'
import {
  CountryCode,
  getPhoneCode,
  isSupportedCountry,
  isValidNumberForRegion,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'
import { useContext, useEffect } from 'react'
import PInput from 'react-phone-input-2'
import errorContext from '@/shared/Forms/ValidationForm/ErrorContext'
import phoneInputUtils from '@/shared/Inputs/PhoneInput/phoneInput.utils'

type Props = ITextInputProps & { countryCode?: string }

const PhoneInput = ({ onChange, ...props }: Props) => {
  const context = useContext(errorContext)
  const error = context.getError(props.name)

  const normalizedCode = isSupportedCountry(props.countryCode) ? props.countryCode : 'US'
  const phoneCode = getPhoneCode(normalizedCode as CountryCode)

  useEffect(() => {
    let value = props.value || ''
    value = phoneInputUtils.addPlus(value)

    const isValid = isValidNumberForRegion(value, normalizedCode as CountryCode)
    context.onValidate(props.name, isValid ? '' : '-')

    if (!isValid) {
      onChange(getPhoneCode(normalizedCode as CountryCode), props.name, false)
    }
  }, [normalizedCode])

  const handleChange = (value: string) => {
    if (!value) return

    const val = phoneInputUtils.addPlus(value)

    const isValid = isValidNumberForRegion(val, normalizedCode as CountryCode)
    context.onValidate(props.name, isValid ? '' : '-')
    onChange(val, props.name, isValid)
  }

  const handlePaste = (ev: ClipboardEvent) => {
    ev.stopPropagation()
    ev.preventDefault()

    const textData = ev.clipboardData.getData('text')
    const data = phoneInputUtils.trimPlus(textData)

    if (data.startsWith(phoneCode)) {
      if (isValidPhoneNumber(data, normalizedCode as CountryCode)) {
        const parsed = parsePhoneNumber(data, normalizedCode as CountryCode)
        onChange(phoneInputUtils.addPlus(parsed.number), props.name, true)
        context.onValidate(props.name, '')
      }
    }
  }

  let val = props.value || ''
  val = phoneInputUtils.trimPlus(val)

  return (
    <div className={css.wrapper} style={props.wrapperStyle}>
      <PInput
        countryCodeEditable={false}
        country={normalizedCode}
        inputProps={{
          required: props.required,
          'data-invalid': Boolean(error),
          onPaste: handlePaste,
          name: props.name,
        }}
        value={val}
        inputClass="etba-input"
        placeholder="Номер телефона"
        disableDropdown={true}
        specialLabel=""
        onChange={handleChange}
      />
      {error && error !== '-' && <p className="etba-input_error text--danger">{error}</p>}
    </div>
  )
}

export default PhoneInput
