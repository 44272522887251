import { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps, FC } from 'react'

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  theme: 'primary' | 'grey' | 'danger' | 'success' | 'success-dashed' | 'orange'
  loading?: boolean
  glare?: boolean
  wrapperStyle?: CSSProperties
}

const Button: FC<IProps> = ({ children, theme, loading, wrapperStyle, ...props }) => {
  const normalized = wrapperStyle || {}

  return (
    <div style={{ position: 'relative', overflow: 'hidden', width: '100%', ...normalized }}>
      <button
        {...props}
        onClick={loading ? undefined : props.onClick}
        className={`etba-button ${props.className ?? ''}`}
        data-theme={theme}
        data-loading={loading ? 'true' : 'false'}
        data-glare={props.glare}
      >
        <span>{children}</span>
      </button>
    </div>
  )
}

export default Button
