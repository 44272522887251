import { useState } from 'react'
import { IHTTPErrorResponse } from '@/types/http.types'
import globalUtils from '@/utils/global.utils'

const useValidationForm = () => {
  const [valid, setIsValid] = useState(true)
  // General description on the error
  const [errorMsg, setErrorMsg] = useState('')
  // If the server responded with 400 status code, here we can find map with field name as key and error message as value
  const [errors, setErrors] = useState<Record<string, string>>({})
  // Before submitting a form we can display suggestions like "Maybe you mean..."
  const [suggestions, setSuggestions] = useState<Record<string, string | JSX.Element>>({})

  const handleErrorResponse = (resp: IHTTPErrorResponse) => {
    setErrorMsg(resp.message)

    if (resp.body) {
      setErrors(globalUtils.flatObject(resp.body))
    }
  }

  const createSuggestion = (name: string, message: string | JSX.Element) => {
    setSuggestions((prev) => ({ ...prev, [name]: message }))
  }

  const removeSuggestion = (name: string) => {
    setSuggestions((prev) => ({ ...prev, [name]: null }))
  }

  return {
    errors,
    errorMsg,
    setErrors,
    setErrorMsg,
    valid,
    suggestions,
    setIsValid,
    handleErrorResponse,
    createSuggestion,
    removeSuggestion,
  }
}

export default useValidationForm
