import circleLogoSrc from '@/public/circleLogo.png'
import css from './circleLogoLoader.module.css'
import Image from '@/shared/Image'

const CircleLogoLoader = () => {
  return (
    <div className={css.wrapper}>
      <Image src={circleLogoSrc} alt="loader" priority={true} height={60} width={60} displayLoader={false} />
    </div>
  )
}

export default CircleLogoLoader
