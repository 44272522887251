import { createContext } from 'react'

type TOnValidate = (name: string, error: string) => void

const ErrorContext = createContext<{
  onValidate: TOnValidate
  getError: (name: string) => string
  getSuggestion: (name: string) => string | JSX.Element
}>({
  onValidate: () => {},
  getError: () => '',
  getSuggestion: () => '',
})
export default ErrorContext
