import css from './splatNumberInput.module.css'
import { useRef, useState } from 'react'
import SplatInput from '@/shared/Inputs/SplatNumberInput/SplatInput'

interface IProps {
  digitsCount: number
  name: string
  value: string
  onChange: (value: string, name: string) => void
  invalid?: boolean
}

/** The input component provides new input tag for each digit */
const SplatNumberInput = (props: IProps) => {
  const valueArr = useRef(Array(props.digitsCount).fill(''))
  const [cursor, setCursor] = useState(0)

  const handlePaste = (value: string) => {
    const pasteLength = Math.min(value.length, props.digitsCount)

    for (let i = 0; i < pasteLength; ++i) valueArr.current[i] = value[i]
    setCursor(pasteLength - 1)

    const updatedValue = valueArr.current.filter(Boolean).join('')
    props.onChange(updatedValue, props.name)
  }

  const handleChange = (value: string, index: number) => {
    valueArr.current[index] = value

    const updatedValue = valueArr.current.filter(Boolean).join('')
    props.onChange(updatedValue, props.name)
  }

  const handleNext = (value: string, index: number) => {
    const i = index + 1
    if (i > props.digitsCount - 1) return

    valueArr.current[i] = value
    setCursor(i)

    const updatedValue = valueArr.current.filter(Boolean).join('')
    props.onChange(updatedValue, props.name)
  }

  const handlePrev = (index: number) => {
    valueArr.current[index] = ''
    const updatedValue = valueArr.current.filter(Boolean).join('')

    props.onChange(updatedValue, props.name)
    setCursor(Math.max(index - 1, 0))
  }

  const renderInputs = () => {
    let inputs = []
    for (let i = 0; i < props.digitsCount; ++i) {
      inputs.push(
        <SplatInput
          key={i}
          value={valueArr.current[i]}
          onChange={handleChange}
          index={i}
          active={i === cursor}
          onNext={handleNext}
          onPrev={handlePrev}
          invalid={props.invalid}
          onPaste={handlePaste}
        />
      )
    }

    return <>{inputs}</>
  }

  return <div className={css.wrapper}>{renderInputs()}</div>
}

export default SplatNumberInput
