const trimPlus = (phone: string) => {
  return phone.startsWith('+') ? phone.slice(1) : phone
}

const addPlus = (phone: string) => {
  return phone.startsWith('+') ? phone : '+' + phone
}

const phoneInputUtils = { trimPlus, addPlus }
export default phoneInputUtils
