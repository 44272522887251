type Value = string | number | boolean | undefined | null;
type Mapping = Record<string, unknown>;
type Argument = Value | Mapping | ArgumentArray;
interface ArgumentArray extends Array<Argument> {}

/** Implementation of the "classnames" library method. Used to decrease build size  */
const cn = (...arg: ArgumentArray) => {
  if (typeof arg[0] === "string") return arg.join(" ");

  const classes = [];

  for (const className in arg) {
    if (arg[0][className]) classes.push(`${className}`);
  }

  return classes.join(" ");
};

export default cn;
