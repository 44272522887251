import CuteLayout from '@/shared/Layouts/CuteLayout/CuteLayout'
import css from './authPage.module.css'
import SmilingFace from '@/public/smilingface.svg'
import useAuthPage from '@/components/Auth/components/AuthPage/useAuthPage'
import TextInput from '@/shared/Inputs/TextInput/TextInput'
import Button from '@/shared/Button/Button'
import AuthConfirm from '@/components/Auth/components/AuthPage/AuthConfirm'
import Star from '@/public/star.svg'
import ValidationForm from '@/shared/Forms/ValidationForm/ValidationForm'
import CheckboxWithInscription from '@/shared/Inputs/CheckboxWithInscription/CheckboxWithInscription'
import Tabs from '@/shared/Inputs/Tabs'
import PhoneInput from '@/shared/Inputs/PhoneInput/PhoneInput'
import { allowPhoneAuth } from '@/shared/constants'
import cn from '@/shared/cn'
import { useIntl } from 'react-intl'
import AuthLanguagePicker from '@/components/Auth/components/AuthLanguagePicker'

const AuthPage = () => {
  const intl = useIntl()
  const authCtrl = useAuthPage()

  if (authCtrl.contactSubmitted) {
    return (
      <AuthConfirm
        loading={authCtrl.loading}
        contact={authCtrl.contact}
        onCancel={authCtrl.handleContactConfirmCancel}
        onConfirm={authCtrl.handleContactConfirm}
        code={authCtrl.confirmationCode}
        onCodeChange={authCtrl.setConfirmationCode}
        error={authCtrl.error}
        contactType={authCtrl.contactType}
        {...authCtrl.authConfirmCtrl}
      />
    )
  }

  const renderContactInput = () => {
    if (authCtrl.contactType === 0) {
      return (
        <TextInput
          wrapperStyle={{ marginBottom: '20px' }}
          placeholder={intl.formatMessage({ id: 'auth.enter_email' })}
          value={authCtrl.contact}
          name="email"
          required={true}
          autoComplete="on"
          onChange={authCtrl.handleEmailChange}
          type="email"
          style={{ textAlign: 'center' }}
        />
      )
    }

    return (
      <PhoneInput
        value={authCtrl.contact}
        name={'phone'}
        onChange={authCtrl.handleEmailChange}
        style={{ marginBottom: '20px' }}
      />
    )
  }

  return (
    <>
      <CuteLayout>
        <div className={`flex-horizontal-centered ${css.wrapper}`}>
          <div className={css.subheader}>
            <Star className={css.leftStar} />
            <Star className={css.rightStart} />
            <p className={cn(css.paragraph, 'text--default')}>{intl.formatMessage({ id: 'auth.start' })}</p>
            <SmilingFace className={css.smilingFace} />
          </div>
          <ValidationForm
            className="flex-form"
            style={{ alignItems: 'center', zIndex: 2 }}
            errors={authCtrl.errors}
            suggestions={authCtrl.suggestions}
            setIsValid={authCtrl.setIsValid}
            onSubmit={authCtrl.handleContactSubmit}
          >
            {allowPhoneAuth && (
              <Tabs
                tabsContent={[
                  <span className="text-14px text-road text--default">ВОЙТИ ПО EMAIL</span>,
                  <span className="text-14px text-road text--default">ПО НОМЕРУ МОБ.</span>,
                ]}
                selectedTab={authCtrl.contactType}
                onTabSelect={authCtrl.handleContactTypeChange}
              />
            )}
            {renderContactInput()}
            {authCtrl.passwordRequired && !authCtrl.contactSubmitted && (
              <TextInput
                value={authCtrl.password}
                name="password"
                onChange={authCtrl.setPassword}
                type="password"
                style={{ textAlign: 'center' }}
                placeholder={intl.formatMessage({ id: 'password' })}
              />
            )}
            <CheckboxWithInscription
              checked={authCtrl.agreementApproved}
              name={'userAgreement'}
              onChange={authCtrl.handleAgreementToggle}
              className="text-14px text--default"
            >
              {intl.formatMessage({ id: 'auth.agree_with' })}{' '}
              <a href="https://82box.ru/privacy" target="_blank">
                {intl.formatMessage({ id: 'auth.personal_data_conditions' })}
              </a>
              ,{' '}
              <a href="https://82box.ru/terms-and-conditions" target="_blank">
                {intl.formatMessage({ id: 'auth.user_conditions' })}
              </a>{' '}
              {intl.formatMessage({ id: 'auth.and' })}{' '}
              <a href="https://82box.ru/delivery-and-return-policy" target="_blank">
                {intl.formatMessage({ id: 'auth.shipping_conditions' })}
              </a>
            </CheckboxWithInscription>
            <span className="text--danger">{authCtrl.error}</span>
            <div className="flex-form">
              <Button
                type="submit"
                theme={'primary'}
                disabled={
                  !authCtrl.contactValid ||
                  !authCtrl.agreementApproved ||
                  (!authCtrl.password && authCtrl.passwordRequired)
                }
                loading={authCtrl.loading}
              >
                {intl.formatMessage({ id: 'auth.login' })}
              </Button>
              <AuthLanguagePicker />
            </div>
          </ValidationForm>
        </div>
      </CuteLayout>
    </>
  )
}

export default AuthPage
