import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAuthState } from '@/components/Auth/auth.types'

const initialState: IAuthState = {
  authState: 'unknown',
  accessToken: null,
  refreshToken: null,
  errorMessage: null,
  accessExpires: 0,
  lang: null,
}

const slice = createSlice({
  name: 'etba/auth',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<IAuthState>>) => {
      for (const key in action.payload) {
        state[key] = action.payload[key]
      }
    },
    logout: (state) => {
      for (const key in initialState) {
        state[key] = initialState[key]
      }
      state.authState = 'logged_out'
    },
  },
})

export const authSliceActions = slice.actions

const authReducer = slice.reducer
export default authReducer
